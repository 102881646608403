import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout"
import "../../../styles/odooServices.scss"
import "../../../styles/services.scss"
import "../../../styles/caseStudy.scss"
import News from "../../../components/News"
import ProductTopSection from "../../../components/ProductTopSelection"
import WhyChooseVisiomateforD365 from "../../../components/WhyChooseVisiomateforD365"
import Wethoughtyoumighthavequestions from "../../../components/Wethoughtyoumighthavequestions"
import CaseStudeiesSimplified from "../../../components/CaseStudeiesSimplified"
import ImageWithTextSection from "../../../components/ImageWithTextSection"
import IconsAdded from "../../../components/IconsAdded"

const Visioedge = ({ data }) => {
  return (
    <div className="CaseStudeies">
      <Layout pageTitle={"Visiomate - Visioedge - ERP Solution "}
      descrip = {"Our visioedge solution provides a complete solution for managing your business. From finance and HR to inventory and sales, streamline your operations with ease. Explore now!"}>
        <ProductTopSection
          headerData={{
            title:
              "Visioedge: a complete ERP system best suited for many industries i.e Any Retails, Restaurant, Complex Warehousing etc.  ",
            peragraph:
              "Indulge in the extraordinary with Visioedge—an Odoo-based solution meticulously crafted exclusively for your business needs. Elevate your operations, streamline efficiency, and experience unparalleled simplicity tailored just for you.",
          }}
          data={data}
        />
        <div className="position-relative">
        <WhyChooseVisiomateforD365
          data={{
            title: "Why Choose Visioedge?",
            headings: [
              {
                heading: "Operational Efficiency",
                description:
                  "Visioedge optimizes business processes, automating routine tasks and fostering a more efficient operational environment.",
              },
              {
                heading: "Enhanced Collaboration",
                description:
                  "Visioedge serves as a hub for collaboration, fostering seamless communication and information sharing across different departments.",
              },
              {
                heading: "Optimized Inventory Control",
                description:
                  "Visioedge streamlines inventory management, reducing holding costs, and ensuring timely fulfillment of orders.",
              },
              {
                heading: "Informed Decision-Making",
                description:
                  "Real-time access to accurate data in Visioedge empowers decision-makers, enabling them to make strategic choices that positively impact the organization.",
              },
              {
                heading: "Holistic Data Integration",
                description:
                  "Integration of data across departments in Visioedge provides a comprehensive view of the organization, allowing for better-informed decision-making.",
              },
              {
                heading: "Time and Cost Savings",
                description:
                  "Automation of tasks, such as inventory management and sales reporting, saves time and reduces the need for manual labor.",
              },
            ],
          }}
          dataFormImages={data}
        />
        <IconsAdded iconColor={"yellow"}/>
        </div>
        <ImageWithTextSection
          caseStudyData={{
            data: [
              {
                heading: "Touch Friendly POS Interface for Retailers",
                description:
                  "The touch-friendly interface streamlines transactions, allowing users to easily navigate through menus, select items, and process payments with a simple tap or swipe.",
              },
              {
                heading: "Shop Demand Management",
                description:
                  "Efficient demand management helps businesses maintain optimal inventory levels by aligning stock levels with actual customer demand.",
              },
              {
                heading: "Orders History & Refund ",
                description:
                  "A detailed order history allows customers to track their purchases, view past transactions, and monitor the status of their orders. An efficient refund system ensures that customers receive timely reimbursements, contributing",
              },
              {
                heading: "Multiple Payment Methods",
                description:
                  "Our solution ensures seamless payment experiences by effortlessly configuring multiple payment methods. Embrace the future of payments with ease and versatility.",
              },
              {
                heading: "Dynamic Kitchen Preparation Screen",
                description:
                  "This technology leverages dynamic, real-time information to enhance communication and coordination between the kitchen staff, improving overall kitchen operations.",
              },
              {
                heading: "Order Status Screen for Cusotmers",
                description:
                  "Ensuring that the KOT screen is automatically updated with new orders and reflects any changes made at the Serving front.",
              },
              {
                heading: "Robust Filtration ",
                description:
                  "Offer a seamless data exploration experience with advanced filters for refining data based on parameters like date ranges and categories. Enhance data retrieval with dynamic search functionality and enable complex queries through multi-criteria filtration",
              },
              {
                heading: "Personalized Data Views",
                description:
                  "Tailor the data view to individual preferences through customizable views, allowing users to adjust columns, layout, and arrangement.",
              },
              {
                heading: "Kanban / Card View",
                description:
                  "It provides a clear, real-time overview of tasks, allowing teams to see the status of work at a glance. Users can drag and drop cards between columns, making it easy to track progress and manage tasks in a visual and intuitive way.",
              },
              {
                heading: "List Data View",
                description:
                  "It is a structured and detailed representation that allows users to view and manipulate data in a spreadsheet-like format.",
              },
              {
                heading: "Mobile app for sales overview & purchase processing",
                description:
                  "A detailed order history allows customers to track their purchases, view past transactions, and monitor the status of their orders. An efficient refund system ensures that customers receive timely reimbursements, contributing",
              },
              {
                heading: "Dashboards",
                description:
                  " Graphs, charts, and other visual elements make complex data more comprehensible, enabling users to quickly grasp trends, patterns, and outliers.",
              },
              {
                heading: "Reporting & Analysis",
                description:
                  "Analytics help identify trends and patterns in sales, customer behavior, and market dynamics, allowing businesses to capitalize on opportunities and mitigate challenges.",
              },
            ],
          }}
          dataFormImages={data}
        />
        <CaseStudeiesSimplified
          TextData={{
            title: "Simplified Steps to get you Started ",
            Data: [
              {
                peragraph: "Define Requirements",
                list: [
                  "Clearly outline your business objectives and identify the specific challenges or needs that the software solution should address.",
                  "List down the essential features and functionalities required.",
                ],
              },
              {
                peragraph: "Demo and Evaluation",
                list: [
                  "Schedule product demonstrations. ",
                  "Evaluate the software's usability, features, and performance. ",
                  "Engage key stakeholders in the evaluation process.",
                ],
              },
              {
                peragraph: "Scalability and Integration",
                list: [
                  "Identify the potential integrations required by the customer.",
                  "Plan the implementation of identified integrations.",
                ],
              },
              {
                peragraph: "UAT & User Feedback",
                list: [
                  "UAT period allows customer to assess the system performance and suitability for the specific business needs.",
                  "Customer’s insights can provide valuable perspectives on usability, functionality mismatch.",
                ],
              },
              {
                peragraph: "User Training",
                list: [
                  " User training is a critical component of successfully implementing the software solution.",
                  "Proper training ensures that users can effectively and efficiently utilize the software, maximizing its benefits.",
                ],
              },
              {
                peragraph: "Post-Implementation Support",
                list: [
                  "Provide ongoing support for users.",
                  "Address any post-implementation issues.",
                  "Evaluate and implement system enhancements based on feedback.",
                ],
              },
            ],
          }}
        />
        <Wethoughtyoumighthavequestions
          data={[
            {
              question: "What is Visioedge ERP?",
              answer:
                "Visioedge ERP is a comprehensive Enterprise Resource Planning (ERP) system designed to streamline and optimize various aspects of business operations. It integrates core business processes such as finance, inventory management, sales, purchasing, production, and human resources into a unified platform.",
              answer1:
                "Visioedge ERP provides businesses with real-time visibility, control, and insights to enhance efficiency, productivity, and decision-making across the organization. Its customizable features, scalability, and flexibility make it suitable for businesses of all sizes and industries, helping them manage their resources effectively and drive growth.",
              answer2: "",
              answer3: "",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
            {
              question: "What modules are included in Visioedge?",
              answer:
                "Visioedge encompasses a wide range of modules to address diverse business needs.",
              answer1:
                "Basic version includes: Invoicing, Inventory Management, Sales, Purchasing, POS.",
              answer2:
                "Manufacturing version: Production, Financing, Human Resources, Inventory Management, Sales, Purchasing, POS.",
              answer3:
                "Advance version includes: CRM, Production, Financing, Human Resources, Project Management, Supply Chain Management, POS.",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
            {
              question: "Is Visioedge customizable to fit our business needs?",
              answer:
                "This is one of our core offering that we can provide a customized solution just to fulfill you needs, we are selling solution, not a product.",
              answer1: "",
              answer2: "",
              answer3: "",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
            {
              question:
                "How does Visioedge support integration with existing systems?",
              answer:
                "Visioedge provides APIs that allow other systems to communicate and exchange data with it. This enables integration with third-party applications, databases, or software solutions used by the organization.",
              answer1:
                "Visioedge supports standard communication protocols such as RESTful APIs. Additionally, Visioedge allows for importing and exporting data in various formats such as CSV, Excel, XML, or JSON. ",
              answer2: "",
              answer3: "",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
            {
              question: "What deployment options are available for Visioedge?",
              answer:
                "Visioedge is a Software as a Service (SaaS) solution, With Visioedge's SaaS model, users can access the platform securely via the internet, eliminating the need for on-premises infrastructure. This flexible and scalable approach ensures seamless updates, easy access from any location, and hassle-free maintenance, empowering businesses to focus on their core operations while maximizing efficiency and productivity.",
              answer1:
                "A customer can ask for qoutation by sharing the requiment.",
              answer2: "",
              answer3: "",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "sharerequirment",
            },
            {
              question:
                "What training and support services are provided with Visioedge?",
              answer:
                "Visioedge comes with extensive documentation, user guides, tutorials, and FAQs to support self-learning and troubleshooting. The knowledge base contains resources for both beginners and advanced users, enabling users to find answers to their questions quickly.",
              answer1:
                "A customer can contact us via support service to have personlized training programs tailored to the specific needs if required.",
              answer2: "",
              answer3: "",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
            {
              question:
                "What are the licensing and pricing options for Visioedge?",
              answer:
                "Visioedge offers tiered pricing plans to accommodate businesses of different sizes and needs. Each tier includes a set of features and functionalities, with pricing varying based on the chosen plan.",
              answer1: "Please connect with us for qoutation",
              answer2: "",
              answer3: "",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "sharerequirment",
            },
            {
              question: "How long does it take to implement Visioedge?",
              answer:
                "The implementation timeline for Visioedge can vary depending on several factors, including the size and complexity of the organization, the scope of the implementation, and the level of customization required. However, if a customer choose the basic plan, it would just take 24 hours to setup and configure.",
              answer1: "",
              answer2: "",
              answer3: "",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
            {
              question: "Is Visioedge scalable to accommodate business growth?",
              answer:
                "Visioedge is built on a flexible and modular architecture that enables easy scalability. As businesses grow, they can add new modules, features, or functionalities to Visioedge to meet their expanding requirements without compromising performance or stability.",
              answer1:
                "Visioedge offers cloud-based deployment options, leveraging the scalability and elasticity of cloud infrastructure. Businesses can scale their usage of Visioedge resources up or down based on demand, ensuring they have the computing power and storage capacity they need without overprovisioning or underutilizing resources.",
              answer2: "",
              answer3: "",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
            {
              question:
                "What security measures are in place to protect our data in Visioedge?",
              answer:
                "Visioedge encrypts data both in transit and at rest using industry-standard encryption algorithms. This ensures that sensitive information is protected from unauthorized access or interception, whether it's being transmitted over the network or stored in databases.",
              answer1:
                "Visioedge implements strict access control mechanisms to regulate user access to data and functionality within the platform. Role-based access control (RBAC) allows administrators to define granular access permissions based on user roles and responsibilities, ensuring that users only have access to the data and features they need to perform their duties.",
              answer2:
                "Visioedge supports strong authentication methods, including multi-factor authentication (MFA), to verify the identity of users accessing the platform. Additionally, Visioedge employs robust authorization mechanisms to enforce access policies and prevent unauthorized actions within the system.",
              answer3: "",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
            {
              question:
                "Can Visioedge be accessed remotely or on mobile devices?",
              answer:
                "Visioedge is accessible via web browsers, allowing users to log in to the platform remotely from any internet-enabled device, including desktop computers, laptops, and tablets. This web-based access ensures that users can securely access Visioedge's features and functionalities without the need for specialized software or installations.",
              answer1:
                "Visioedge may offer dedicated mobile applications for popular mobile platforms, such as iOS and Android. These mobile apps provide optimized user experiences tailored to the specific needs and capabilities of mobile devices, allowing users to access key features and perform essential tasks while on the go.",
              answer2: "",
              answer3: "",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
            {
              question: "What industries or sectors does Visioedge cater to?",
              answer:
                "Visioedge is a versatile and adaptable Enterprise Resource Planning (ERP) solution designed to cater to a wide range of industries and sectors. Some of the industries and sectors not limited to that Visioedge can effectively serve include:",
              answer1:
                "Manufacturing: Visioedge provides comprehensive functionality to streamline manufacturing processes, including production planning, inventory management, quality control, and supply chain management. ",
              answer2:
                "Retail and Wholesale: Visioedge offers features tailored to retail and wholesale businesses, including point-of-sale (POS) systems, inventory management, sales order processing, and customer relationship management (CRM). It helps retailers optimize their operations, manage inventory levels, and deliver exceptional customer service.",
              answer3:
                "Distribution and Logistics: Visioedge supports distribution and logistics companies with capabilities for order fulfillment, warehouse management, transportation management, and route optimization. It helps streamline distribution processes, reduce shipping costs, and improve delivery efficiency.",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
          ]}
          title="We thought you might have questions"
        />
        <News isAbout={true} career={true} />
      </Layout>
    </div>
  )
}

export default Visioedge
export const query = graphql`
  query VisiopackImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    HeaderImg: file(relativePath: { eq: "caseStudies/visioedgeheader.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImages: file(relativePath: { eq: "prodbg-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img1: file(relativePath: { eq: "caseStudies/Maskgroup9.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(relativePath: { eq: "caseStudies/Group329.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(relativePath: { eq: "caseStudies/Group314.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img4: file(relativePath: { eq: "Visioedge/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(relativePath: { eq: "Visioedge/2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img6: file(relativePath: { eq: "Visioedge/3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img7: file(relativePath: { eq: "Visioedge/4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img8: file(relativePath: { eq: "caseStudies/Maskgroup10.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img9: file(relativePath: { eq: "caseStudies/Group314_1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img10: file(relativePath: { eq: "caseStudies/Maskgroup11.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img11: file(relativePath: { eq: "caseStudies/Mobile_3Screens-01_1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img12: file(relativePath: { eq: "caseStudies/Maskgroup12.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img13: file(relativePath: { eq: "caseStudies/Maskgroup13.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
